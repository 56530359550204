import React, { useState } from "react";
import questions from "../../../static/assets/images/home-icons/question icon.svg";
import visitors from "../../../static/assets/images/home-icons/viewers.svg";
import asha from "../../../static/assets/images/home-icons/Asha worker.svg";
import { Container, Row, Col, Card } from "react-bootstrap";
import CountUp from "react-countup";
import i18n from "i18n-js";
const Counter = () => {

  return (
    <div
      className="counter-wrapper contact-form-bg"
    >
      <Container data-aos='zoom-in-up'>
        <div className="counters-bg-img">
          <Row noGutters={true} >
              <Col xs={12} sm={12} md={4} lg={4} xl={4} className="counter-border" >
                <div className="text-center pb-10 pt-10">
                  <div className="text-center">
                    <img src={questions} />
                  </div>
                  <CountUp
                    duration={60}
                    end={789}
                    className="hit-counter-num"
                    data-aos='zoom-in'
                  /><br />
                  <span className="text-shadesblack counter-p"  dangerouslySetInnerHTML={{ __html: i18n.t('num-of-questions') }}></span>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4} className="counter-border">
                <div className="text-center pb-10 pt-10">
                  <div className="text-center">
                    <img src={visitors} />
                  </div>
                  <CountUp
                    duration={60}
                    end={560}
                    className="hit-counter-num"
                  /><br />
                  <span className="text-shadesblack counter-p" dangerouslySetInnerHTML={{ __html: i18n.t('num-of-visitors') }}></span>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="text-center pb-10 pt-10">
                  <div className="text-center">
                    <img src={asha} />
                  </div>
                  <CountUp
                    duration={60}
                    end={987}
                    className="hit-counter-num"
                  /><br />
                  <span className="text-shadesblack counter-p" dangerouslySetInnerHTML={{ __html: i18n.t('num-of-asha') }}></span>
                </div>
              </Col>
            
          </Row>
        </div>
      </Container >
    </div >
  );
};

export default Counter;
