import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import api from "../../utility/apis";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import i18n from "i18n-js";
const getIsMobile = () => window.innerWidth <= 768;
const WhatIf = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [allBanners, setAllBanners] = useState([]);
  const [width, height] = useWindowSize();
  const onlyWidth = useWindowWidth();
  const getAllBanners = async () => {
    let rotatingBanners = [];
    let { data: banner } = await api
      .get("/trd-portal-main-banners?populate=*&sort=id:asc", 3000)
      .catch((err) => console.log("something went wrong!!!"));
    if (banner) {
        console.log(banner)
        setAllBanners(banner.data)
    }
  };
  useEffect(() => {
    getAllBanners().catch((err) => console.log("something went wrong!!"));
  }, []);
  return (
    <>
      <section  className="pb-10">
         <Carousel interval={5000} loop>
          {allBanners && allBanners.length > 0 && allBanners.map((item, i) => {
            const Cards =
              item.attributes.show_banner === "show" ? (
                <Carousel.Item className="rotating-banner-padding">
                  {width < 768 ?
                      <img
                        src={item.attributes.image.data[1].attributes.formats ?item.attributes.image.data[1].attributes.formats.small.url :item.attributes.image.data[1].attributes.formats.thumbnail.url}
                        alt="First slide"
                        width="100%"
                      /> :
                      <img
                        src={item.attributes.image.data[0].attributes.formats.large.url?item.attributes.image.data[0].attributes.formats.large.url:item.attributes.image.data[0].attributes.formats.thumbnail.url}
                        alt="First slide"
                        width="100%"
                      />
                  }
                  <Carousel.Caption className="carousel-caption-banner">
                    <div class="">
                      <p dangerouslySetInnerHTML={{ __html: i18n.t(item.attributes.question1) }}></p>
                      <p dangerouslySetInnerHTML={{ __html: i18n.t(item.attributes.question2) }}></p><br />
                      <h3 dangerouslySetInnerHTML={{ __html: i18n.t(item.attributes.main_question) }}></h3>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              ) : (
               <></>
              )
            return Cards;
        })}
        </Carousel>
      </section>
    </>
  );
};
export default WhatIf;

// import React, { useState, useEffect } from "react";
// import { Carousel } from "react-bootstrap";
// import api from "../../utility/apis";
// import {
//   useWindowSize,
//   useWindowWidth,
//   useWindowHeight,
// } from '@react-hook/window-size'
// import i18n from "i18n-js";
// const getIsMobile = () => window.innerWidth <= 768;
// const WhatIf = () => {
//   const [isMobile, setIsMobile] = useState(getIsMobile());
//   const [allBanners, setAllBanners] = useState([])
//   const [width, height] = useWindowSize()
//   const onlyWidth = useWindowWidth()
//   let screenSize = window.innerWidth
//   let mobileBanner = [];
//   let desktopBanner = [];
 
//   const getAllBanners = async () => {

//     let rotatingBanners = [];
//     let { data: banner } = await api
//       .get("/trd-portal-main-banners?populate=*&sort=id:asc", 3000)
//       .catch((err) => console.log("something went wrong!!!"));
//     if (banner) {
  
//       banner.data.map((ele) => {
      
//         rotatingBanners.push({
//           title: ele.attributes.main_question,
//           subTitle1: ele.attributes.question1,
//           subTitle2: ele.attributes.question2,
//           showBanner: ele.attributes.show_banner,

//         });
//         ele.attributes.image.data.map((item, index) => {
//           console.log(index)
//           if (index === 0) desktopBanner.push(item)
//           if (index === 1) mobileBanner.push(item)
//         })
//       });
    
//     }
   
//     console.log(desktopBanner, mobileBanner)
//   }
//   useEffect(() => {
  
//     getAllBanners().catch((err) => console.log("something went wrong!!"));

//   }, []);
 
//   return (
//     <>
//       <section
//         style={{ backgroundColor: "#d3d3d330" }}
//         className="pb-10"
//       >
//         {console.log(mobileBanner, "allBanners")}
//         <Carousel interval={null}>
//           {mobileBanner && mobileBanner.length > 0 && mobileBanner.map((item, i) => {
//             console.log(mobileBanner, "loki")
//             const Cards =
//               item.showBanner === "show" ? (
//                 <Carousel.Item className="rotating-banner-padding">
//                   {console.log(width, onlyWidth)}
//                   {width < 768 ?
//                       <img
//                         src={item.attributes.image.data[1].formats.small.url}
//                         alt="First slide"
//                         width="100%"
//                       /> :
//                       <img
//                         src={item.attributes.image.data[0].formats.large.url}
//                         alt="First slide"
//                         width="100%"
//                       />
//                   }
//                   <Carousel.Caption className="carousel-caption-banner">
//                     <div class="">
//                       <p dangerouslySetInnerHTML={{ __html: i18n.t(item.subTitle1) }}></p>
//                       <p dangerouslySetInnerHTML={{ __html: i18n.t(item.subTitle2) }}></p><br />
//                       <h3 dangerouslySetInnerHTML={{ __html: i18n.t(item.title) }}></h3>
//                     </div>
//                   </Carousel.Caption>
//                 </Carousel.Item>
//               ) : (
//                 <></>
//               );
//             return Cards;
//           })}
//         </Carousel>
//       </section>
//     </>

//   );
// };
// export default WhatIf;
