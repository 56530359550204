import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import api from "../utility/apis";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import i18n from "i18n-js";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
const Sliders = () => {
    const [allSupporters, setAllSupporters] = useState([])
    const [index, setIndex] = useState(0);
    const getAllSupporters = async () => {
        let getSupporters = [];
        let { data: supporters } = await api
            .get("/trd-portal-sponsor-logos?populate=*&sort=id:asc", 3000)
            .catch((err) => console.log("something went wrong!!!"));
        if (supporters) {
            supporters.data.map((ele) => {
                getSupporters.push({
                    image: ele.attributes.image.data.attributes.url,
                    show: ele.attributes.show_logo
                });
            });
            setAllSupporters(getSupporters)
            console.log(getSupporters, "uyuuiuiuio")
        }
    }
    useEffect(() => {
        // get data from GitHub api
        getAllSupporters().catch((err) => console.log("something went wrong!!"));
    }, []);
    return (
        <section className="our-supporters white-color">
            <div>
                <div className="our-supporters-text">
                    <h1>{i18n.t('our-supporters')}</h1>
                </div>
                <Container>
                    <Row className="justify-content-center" id="sponsers">

                        {allSupporters && allSupporters.length > 0 && allSupporters.map((item, i) => {
                            { console.log(item.show === "show" && item) }
                            const Cards =
                                item.show === "show" && (
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <img
                                            src={item.image}
                                            alt="sponsers-logo"
                                            width="100%"
                                            className="supporters-radius"
                                        />
                                    </Col>
                                )
                            return Cards;

                        })}


                    </Row>
                </Container>

            </div >
        </section>
    )
}
export default Sliders