import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import i18n from "i18n-js";
import { Link } from "gatsby";
import appImg1 from "../../../static/assets/images/home-icons/app-flow1.png"
import appImg2 from "../../../static/assets/images/home-icons/app-flow2.png"
import googlePlay from "../../../static/assets/images/home-icons/google-play.png"
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
const AppFlow = () => {
    return (
        <SimpleReactLightbox>
            <section id="app-flow" className="pb-30 light-grey">

                <Container>
                    <Row noGutters className="justify-content-between">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="app-content-sec1">
                                <h1>{i18n.t('app-writeup-heading')}</h1>
                                <SRLWrapper>
                                    <a href={appImg1} target="_blank">
                                        <img src={appImg1} width="100%" />
                                    </a>
                                </SRLWrapper>
                            </div>
                        </Col>

                    </Row>
                    <Row noGutters className="justify-content-center app-flow-margin">
                        <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                        <SRLWrapper>
                            <a href={appImg2}>
                                <img src={appImg2} width="100%" target="_new"/>
                            </a>
                            </SRLWrapper>
                            <div className="text-center mt-20">
                                <Link to="/">
                                    <img src={googlePlay} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        </SimpleReactLightbox>
    )
}
export default AppFlow;