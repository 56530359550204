import React,{useEffect} from "react";
import i18n from "i18n-js";
import theoryApp from "../../../static/assets/images/home-icons/theory-app.png"
import theoryAsha from "../../../static/assets/images/home-icons/theory-asha.png"
import theoryCounsel from "../../../static/assets/images/home-icons/theory-counsel.png"
import theoryPlus from "../../../static/assets/images/home-icons/plus.svg"
import { Container, Col, Row } from "react-bootstrap";
import 'aos/dist/aos.css';
import AOS from 'aos';
const AppWriteup = () => {
  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  });
  return (
    <>
      <section className="contact-form-bg pb-30 white-color" id="theory-content">
        <Container>
          <Row className="justify-content-center text-center">
            <Col xs={12} sm={12} md={8} xl={8} lg={8}>
              <div className="theory-change" >
                <h1>{i18n.t('theory-change1')}</h1>
                <p dangerouslySetInnerHTML={{ __html: i18n.t('theory-change-content') }}></p>
              </div>
            </Col>
          </Row>
          <div className="theory-change text-center pt-20 pb-30">
            <h3>{i18n.t('how-we-intend')}</h3>
          </div>
          <Row className="justify-content-center text-center" id="steps-involve"  data-aos="zoom-in-up">
            <Col xs={12} sm={12} md={3} xl={3} lg={3}>
              <img src={theoryApp} />
              <div>
                <h5 dangerouslySetInnerHTML={{ __html: i18n.t('step1-content') }} className="text-center"></h5>
              </div>
            </Col>
            <Col xs={12} sm={1} md={1} xl={1} lg={1} id="next-theory-arrow">
              <img src={theoryPlus} className="next-arrow" />
            </Col>
            <Col xs={12} sm={12} md={3} xl={3} lg={3}>
              <img src={theoryAsha} />
              <div>
                <h5 className="text-center">{i18n.t('step2-content')}</h5>
              </div>
            </Col>
            <Col xs={12} sm={1} md={1} xl={1} lg={1}>
              <img src={theoryPlus} className="next-arrow" />
            </Col>
            <Col xs={12} sm={12} md={3} xl={3} lg={3}>
              <img src={theoryCounsel} />
              <div>
                <h5 className="text-center">{i18n.t('step3-content')}</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default AppWriteup;