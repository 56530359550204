import React,{useEffect} from "react";
import Layout from "../components/layout";
import HeaderOne from "../components/header/header-one";
import StickyHeader from "../components/header/sticky-header";
import Footer from "../components/footer";
import Counter from "../components/counter/counter";
import WhatIf from "../components/what/what-if";
import Sliders from "../components/sliders";
import AppWriteup from "../components/what/app-writeup";
import AppFlow from "../components/what/app-flow";
import 'aos/dist/aos.css';
import AOS from 'aos';
const HomeOne = () => {
  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  });
  return (
    <Layout pageTitle="TheRedDot">
      <HeaderOne />
      <StickyHeader />
      <section className="main-bg-image">
      <WhatIf />
      <AppWriteup/>
      <AppFlow/>
      <Sliders/>
      <Counter/>
      </section>
      <Footer />
    </Layout>
  );
};

export default HomeOne;
